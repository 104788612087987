<template>
  <div class="community">
    <!--        导航-->
    <rxNavBar title="选择部门"></rxNavBar>
    <!--            搜索栏-->
    <div class="communityDiv">
      <!--            最近浏览-->
        <div class="part-inputpart">

          <div class="part-inputpart-row" @click="initData(1)">
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">公司</span>
            <span class="content-divide">|</span>
            <span class="partInputpartRowNormaltext">{{departmentOne.title || ''}}</span>
            <img class="part-inputpart-row-right" :class=" 1 == departmentLevel ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
          </div>
          <div class="part-inputpart-row" @click="initData(2)">
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">大区</span>
            <span class="content-divide">|</span>
            <span class="partInputpartRowNormaltext">{{departmentTwo.title || ''}}</span>
            <img class="part-inputpart-row-right" :class=" 2 == departmentLevel ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
          </div>
          <div class="part-inputpart-row" @click="initData(3)">
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">门店</span>
            <span class="content-divide">|</span>
            <span class="partInputpartRowNormaltext">{{departmentThree.title || ''}}</span>
            <img class="part-inputpart-row-right" :class=" 3 == departmentLevel ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
          </div>
          <div class="part-inputpart-row" @click="initData(4)">
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">部门</span>
            <span class="content-divide">|</span>
            <span class="partInputpartRowNormaltext">{{departmentFour.title || ''}}</span>
            <img class="part-inputpart-row-right" :class=" 4 == departmentLevel ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
          </div>
          <div class="part-inputpart-row" @click="initData(5)">
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">小组</span>
            <span class="content-divide">|</span>
            <span class="partInputpartRowNormaltext">{{departmentFive.title || ''}}</span>
            <img class="part-inputpart-row-right" :class=" 5 == departmentLevel ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
          </div>

      </div>
      <!--            分割线-->
      <div class="rule"></div>
      <div @click="saveData" class="part part-button" :class=" !Object.keys(departmentOne).length ? '' : 'part-button-enabled' ">确认选择</div>
    </div>
    <van-popup v-model="isDepartmentShow" position="bottom" @close="departmentLevel = ''">
      <van-picker
          show-toolbar
          :columns="departmentArr"
          value-key="title"
          @cancel="isDepartmentShow = false"
          @confirm="confirmDepartmentShow"
      />
    </van-popup>
  </div>
</template>

<script>
import {NavBar, Search, Icon, Area, Col, Row, Picker, Popup} from 'vant';
import {queryDepartment} from "../../../getData/getData";
import {responseUtil} from "../../../libs/rongxunUtil";
import eventBus from "../../../components/rongxun/rx-navBar/envbus.js"
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
export default {
  name: 'SelectDepartment',
  components: {
    [NavBar .name]:NavBar,
    [Search .name]:Search,
    [Icon .name]:Icon,
    [Area .name]:Area,
    [Col .name]:Col,
    [Row .name]:Row,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup
  },
  data() {
    return {
      oldDepartment: {},
      pid: '',
      departmentLevel: '',
      isDepartmentShow: false,
      searchInfo:'',
      searchId:'',
      address :'',
      departmentArr: [],
      departmentOne: {},
      departmentTwo: {},
      departmentThree: {},
      departmentFour: {},
      departmentFive: {},
      likeShowInfos : [],
    }
  },

  mounted() {
    //this.initData()
  },
  methods:{
    confirmDepartmentShow(item){
      switch (this.departmentLevel){
        case 1:
          if(item != this.oldDepartment){
            this.departmentTwo = {}
            this.departmentThree = {}
            this.departmentFour = {}
            this.departmentFive = {}
          }
          this.departmentOne = item || '';
          break
        case 2:
          if(item != this.oldDepartment){
            this.departmentThree = {}
            this.departmentFour = {}
            this.departmentFive = {}
          }
          this.departmentTwo = item || '';
          break
        case 3:
          if(item != this.oldDepartment){
            this.departmentFour = {}
            this.departmentFive = {}
          }
          this.departmentThree = item || '';
          break
        case 4:
          if(item != this.oldDepartment){
            this.departmentFive = {}
          }
          this.departmentFour = item || '';
          break
        case 5:
          this.departmentFive = item || '';
          break
      }
      this.isDepartmentShow = false
    },
    // chickDepartmentShow(level){
    //   this.departmentLevel = level
    //   // switch (level){
    //   //   case 1:
    //   //     this.departmentArr = this.department1Arr;
    //   //     break
    //   //   case 2:
    //   //     this.departmentArr = this.department2Arr;
    //   //     break
    //   //   case 3:
    //   //     this.departmentArr = this.department3Arr;
    //   //     break
    //   //   case 4:
    //   //     this.departmentArr = this.department4Arr;
    //   //     break
    //   //   case 5:
    //   //     this.departmentArr = this.department5Arr;
    //   //     break
    //   // }
    //   this.initData()
    //   //this.isDepartmentShow = true
    // },
    //返回上一层
    saveData(){
      if(!Object.keys(this.departmentOne).length)
        return
      let params = this.departmentOne
      if(Object.keys(this.departmentTwo).length)
        params = this.departmentTwo
      if(Object.keys(this.departmentThree).length)
        params = this.departmentThree
      if(Object.keys(this.departmentFour).length)
        params = this.departmentFour
      if(Object.keys(this.departmentFive).length)
        params = this.departmentFive
      //  //传递一个map，chooseCommunity是key，params是value
      eventBus.$emit('SelectDepartment',params);
      //调用router回退页面
      this.$router.go(-1);
    },

    // 页面初始化
    initData(level) {
      var that = this
      that.departmentLevel = level
      that.departmentArr = []
      let initData = {}
      initData.level = level
      switch (level){
        case 1:
          that.oldDepartment = that.departmentOne;
          break
        case 2:
          if(!that.departmentOne.id)
            return
          initData.pid = that.departmentOne.id;
          that.oldDepartment = that.departmentTwo;
          break
        case 3:
          if(!that.departmentTwo.id)
            return
          initData.pid = that.departmentTwo.id;
          that.oldDepartment = that.departmentThree;
          break
        case 4:
          if(!that.departmentThree.id)
            return
          initData.pid = that.departmentThree.id;
          that.oldDepartment = that.departmentFour;
          break
        case 5:
          if(!that.departmentFour.id)
            return
          initData.pid = that.departmentFour.id;
          that.oldDepartment = that.departmentFive;
          break
      }
      initData.searchContent = that.searchInfo
      queryDepartment(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data.departmentList)
          that.departmentArr = response.data.data.departmentList
          if(that.departmentArr.length)
            that.isDepartmentShow = true
        })
      })

    }
  },
  //路由跳转时销毁全局bus里的监听事件
  beforeDestroy() {
    eventBus.$off('SelectDepartment')
  },
}
</script>

<style lang="less" scoped >

.part-button{
  height: 40px;
  border-radius: 10px;
  background-color: #ededed;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 50px;
}

.part-button-enabled{
  background-image:linear-gradient(to right,#ffc274, #ff5d3b);
}

.content-divide {
  font-size: 12px;
  color: #efefef;
  margin: 0 10px;
}

.part-inputpart {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
}
.part-inputpart-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background-color: #ffffff;
  margin-top: 2px;
}
.part-inputpart-row-header{
  font-weight: 900;
}
.part-inputpart-row-enablenon {
  margin-left: 10px;
}
.partInputpartRowNormaltext{
  color: black;
  width: 100%;
}
.part-inputpart-row-right{
  float: right;
  margin-right: 10px;
}

.part-inputpart-row-right-rightArrow{
  width: 8px;
}

.part-inputpart-row-right-downArrow{
  width: 9px;
}

.part-inputpart-row-right-upArrow{
  width: 9px;
  transform: rotateX(180deg);
}
*{
  margin: 0;
  padding: 0;
}
/*总DIV */
.communityDiv{
  margin: 10px auto;
  width: 92%;
}
.community:after{
  content: "";
  display: block;
  clear: both;
}
/*搜索框DIV*/
.searchDiv{
  line-height: 40px;
  width:100%;
  overflow: hidden;
  .serch{
    width: 92%;
    margin: 0 auto;
    border-radius: 20px;
  }
}
/*位置字体*/
.searchText{
  font-size: 15px;
  font-weight: bold;
  float: left;
  margin-top: 3px;
  margin-left: 0px;
  width: 50px;
}
/*    最近浏览*/
.recentBrowseText{
  font-size: 13px;
  font-weight: bold;
}
/*    最近浏览信息*/
.recentBrowseLi{
  font-size: 14px;
  color: #999999;
  margin-top: 15px;
}
/*    分割线*/
.rule{
  width: 100%;
  height: 0.1px;
  border: 0.8px solid #f0f0f0;
  margin-top: 15px;
  margin-bottom: 28px;
}
/*    模糊显示信息*/
.likeShowLi{
  font-size: 15px;
  margin-top: 15px;
}
</style>
